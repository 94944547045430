<template>
    <div class="bg-wrapper">
        <div class="bg-image" v-for="(image, index) in bgImages" :key="index" :class="{ active: index === activeIndex }" :style="{ 'background-image': image }"></div>
        <div class="outro-container">
            <div class="outro-shopify">
                <p>For now, the physical book is out of print. You can find out more about the book and the interactive art installation it became here:</p>
                <a href="https://robingardener.com/design-publishing" target="_blank">robingardener.com/design-publishing</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            bgImages: [
            'url(/outro/outro-1.jpg)',
            'url(/outro/outro-2.jpg)',
            'url(/outro/outro-3.jpg)',
            'url(/outro/outro-4.jpg)',
            'url(/outro/outro-5.jpg)',
            'url(/outro/outro-6.jpg)',
            'url(/outro/outro-7.jpg)',
            'url(/outro/outro-8.jpg)',
            ],
            activeIndex: 0,
        }
    },
    mounted() {
        this.cycleBackground();
    },
    methods: {
        cycleBackground() {
            setInterval(() => {
                this.activeIndex = (this.activeIndex + 1) % this.bgImages.length;
            }, 10000);
        }
    }
}
</script>

<style scoped>
.bg-wrapper {
    display: flex;
    height: 100vh;
    width: 100vw;
    position: relative;
    /* background-image: url('../../src/assets/images/outro/outro-1.jpg'); */
}

.bg-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  z-index: -1;
  background-size: cover;
  background-position: bottom;
}
.bg-image.active {
  opacity: 1;
  z-index: 0;
}

.outro-container {
    width: 80%;
    display: flex;
    z-index: 5;
    font-family: 'Elza', sans-serif;
    height: min-content;
    padding: 40px 0 0 40px;
}
.outro-container > div {
    width: 50%;
}

.outro-hidden h1 {
    font-family: 'Elza', sans-serif;
    font-weight: 900;
    background-color: rgba(255,255,255,0.8);
    padding: 15px;
    margin: 0;
    width: fit-content;
    border-radius: 5px;
    font-size: 2em;
}

.outro-container > .outro-shopify {
    background-color: rgba(255,255,255,0.9);
    border-radius: 5px;
    padding: 20px;
}

.outro-shopify p {
    margin: 0;
    font-size: 1.5em;
    font-weight: 600;
    width: 75%;
    padding-bottom: 20px;
}

.outro-shopify a {
    text-decoration: none;
    font-size: 1.41em;
    font-style: italic;
    color: #0000EE;

}

iframe {
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: 1000px) {
    .outro-container {
        flex-direction: column;
    }
    .outro-container > .outro-hidden {
        display: none;
    }
    .outro-container > .outro-shopify {
        width: 100%;
        height: 100%;
    }
}
</style>