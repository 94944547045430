<script>
import ProgressBar from "../elements/ProgressBar.vue";
import { inject, computed } from 'vue';

export default {
    components: {
        ProgressBar,
    },
    setup() {
        const state = inject('state');
        const time = computed(() => {
            return state.slideshowActive ? 113000 : 105000;
        })
        return { state, time };
    },
    data() {
        return {
            isInView: false,
            containerClass: '',
            teztext1: null,
            tez1: null,
            tez2: null,
            teztext2: null,
            tez3: null,
            tez4: null,
            teztext3: null,
            tezgroup1: null,
            tez5: null,
            tez6: null,
            tez7: null,
            hasShown: true,
        }
    },
    methods: {
        initAnimation() {
            this.hasShown = false
            this.$emit('disable-move-functions');
            // TIMEOUT START
            setTimeout(() => {
                this.teztext1.style.opacity = 1;
                this.teztext1.setAttribute("data-aos", "fade-in");

                setTimeout(() => {
                    this.tez1.style.opacity = 1;
                    this.tez1.setAttribute("data-aos", "fade-in");
                    this.teztext1.setAttribute("data-aos", "fade-out");
                    this.teztext1.style.opacity = 0;

                    setTimeout(() => {
                        this.tez2.style.opacity = 1;
                        this.tez2.setAttribute("data-aos", "fade-in");

                        setTimeout(() => {
                            this.teztext2.style.opacity = 1;
                            this.teztext2.setAttribute("data-aos", "fade-in");

                            setTimeout(() => {
                                this.tez3.style.opacity = 1;
                                this.tez3.setAttribute("data-aos", "fade-in");
                                this.tez1.setAttribute("data-aos", "fade-out");
                                this.tez1.style.opacity = 0;

                                setTimeout(() => {
                                    this.tez4.style.opacity = 1;
                                    this.tez4.setAttribute("data-aos", "fade-in");
                                    this.tez2.setAttribute("data-aos", "fade-out");
                                    this.tez2.style.opacity = 0;

                                    setTimeout(() => {
                                        this.teztext3.style.opacity = 1;
                                        this.teztext3.setAttribute("data-aos", "fade-in");
                                        this.teztext2.setAttribute("data-aos", "fade-out");
                                        this.teztext2.style.opacity = 0;

                                        setTimeout(() => {
                                            this.tezgroup1.setAttribute("data-aos", "fade-out");
                                            this.tezgroup1.style.opacity = 0;

                                            setTimeout(() => {
                                                this.tez5.style.opacity = 1;
                                                this.tez5.setAttribute("data-aos", "fade-in");

                                                setTimeout(() => {
                                                    this.tez6.style.opacity = 1;
                                                    this.tez6.setAttribute("data-aos", "fade-in");

                                                    setTimeout(() => {
                                                        this.tez7.style.opacity = 1;
                                                        this.tez7.setAttribute("data-aos", "fade-in");
                                                        this.hasShown = true;

                                                        if(this.state.slideshowActive) {
                                                            setTimeout(() => {
                                                            const event = new KeyboardEvent('keydown', {'key':'ArrowDown'});
                                                            window.dispatchEvent(event);
                                                            }, 8000); // 8 seconds delay
                                                        }

                                                    // Time between final tez 2 and 3
                                                    }, 5000)

                                                // Time between final tez 1 and 2
                                                }, 5000)

                                            // Time between white page and final tez image 1
                                            }, 3000)

                                        // Time between all second set of images and white page
                                        }, 30000)

                                    // Time between first image pair to swap (full page 1)
                                    }, 3000)

                                // Time between first image pair to swap (full page 1)
                                }, 3000)

                            // Time between second image pair to swap
                            }, 40000)

                        // Time between first image and right text
                        }, 5000)

                    // Time between first and second image
                    }, 5000)

                // Time between first text and first image
                }, 5000)

            // Time between blank and first text
            }, 1000)
            // TIMEOUT END.
        },
        resetAnimation() {
            const elements = [
                this.teztext1,
                this.tez1,
                this.tez2,
                this.teztext2,
                this.tez3,
                this.tez4,
                this.teztext3,
                this.tez5,
                this.tez6,
                this.tez7,
            ];
            elements.forEach((element) => {
                element.style.opacity = 0;
            });
            this.tezgroup1.style.opacity = 1;
            this.$refs.progressBar.reset();
            this.initAnimation();
        }
    },
    mounted() {
        this.$aos.init();

        this.teztext1 = this.$refs.teztext1;
        this.tez1 = this.$refs.tez1;
        this.tez2 = this.$refs.tez2;
        this.teztext2 = this.$refs.teztext2;
        this.tez3 = this.$refs.tez3;
        this.tez4 = this.$refs.tez4;
        this.teztext3 = this.$refs.teztext3;
        this.tezgroup1 = this.$refs.tezgroup1;
        this.tez5 = this.$refs.tez5;
        this.tez6 = this.$refs.tez6;
        this.tez7 = this.$refs.tez7;

        const resetAnimationOnKeyDown = (event) => {
            if (event.key === 'ArrowLeft' && this.hasShown == true) {
                this.resetAnimation();
            }
        }

        const options = {
            rootMargin: '0px',
            threshold: 0.5,
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    this.isInView = true;
                    if (this.hasShown == null) {
                        this.initAnimation();
                    }
                    window.addEventListener("keydown", resetAnimationOnKeyDown);
                } else {
                    window.removeEventListener("keydown", resetAnimationOnKeyDown);
                }
            });
        },options);
        observer.observe(this.$refs.container);
    },
    watch: {
            hasShown(value) {
            if (value === false) {
                this.$emit('disable-move-functions');
            } else {
                this.$emit('enable-move-functions');
            }
        },
    },
}

</script>
<template>
    <div class="container" ref="container">
        <div class="moreContent" ref="tezgroup1">
            <div class="moreContentHalf" style="display: flex; flex-direction: column; gap: 20px; justify-content: start; margin-top: 40px;">
                <img
                ref="teztext1"
                data-aos="fade-in"
                data-aos-duration="1000"
                data-aos-once="true"
                data-aos-offset="200"
                src="../../assets/images/tez/teztext1.png"
                style="opacity: 0; width: 75%; margin-bottom: -50px; position: relative; z-index: 1;"
                alt="">
                <div style="display: flex; flex-direction: column; justify-content: center; gap: 20px;">
                    <div class="imgPair">
                        <img
                        ref="tez1"
                        data-aos="fade-in"
                        data-aos-duration="1000"
                        data-aos-once="true"
                        data-aos-offset="200"
                        src="../../assets/images/tez/tez-1.jpg"
                        style="opacity: 0; width: 90%; margin-top: -50px;"
                        alt="">
                        <img
                        class="imgPairImg"
                        ref="tez3"
                        data-aos="fade-in"
                        data-aos-duration="1000"
                        data-aos-once="true"
                        data-aos-offset="200"
                        src="../../assets/images/tez/tez-3.jpg"
                        style="opacity: 0; width: 90%; margin-top: -50px;"
                        alt="">
                    </div>
                    <div class="imgPair">
                        <img
                        ref="tez2"
                        data-aos="fade-in"
                        data-aos-duration="1000"
                        data-aos-once="true"
                        data-aos-offset="200"
                        src="../../assets/images/tez/tez-2.jpg"
                        style="opacity: 0; width: 90%;"
                        alt="">
                        <img
                        class="imgPairImg"
                        ref="tez4"
                        data-aos="fade-in"
                        data-aos-duration="1000"
                        data-aos-once="true"
                        data-aos-offset="200"
                        src="../../assets/images/tez/tez-4.jpg"
                        style="opacity: 0; width: 90%;"
                        alt="">
                    </div>
                </div>
            </div>
            <div class="moreContentHalf">
                <div ref="teztext2" style="opacity: 0;">
                    <p class="typedText typedContent">
                        "Early on Monday morning on 31 October 2022, I approached the MI5 headquarters in Westminster, London. Alone, I tried to walk calmly, heart racing, carrying a huge, ridiculously suspect-looking bag with a fire extinguisher full of paint in it. I had a photographer following, and a journalist had been tipped off and was waiting in the area.
                    </p>
                    <p class="typedText typedContent">
                        I arrived later than I'd hoped, and loads of people were going into the building to start work. I waited for a moment when there were fewer people, but I didn't want to hang around for too long with my not-inconspicuous-at-all giant pink bag.
                    </p>
                    <p class="typedText typedContent">
                        I went for it as soon as I was sure I wouldn't accidentally paint anyone, lifting the extinguisher out of the bag and pulling the trigger. Orange paint arced gracefully into the air and onto the building, but suddenly someone was grabbing me and trying to get the extinguisher out of my hands.
                    </p>
                    <p class="typedText typedContent">
                        I wasn't having any of that, and didn't let go. Totally focused on painting the building, I only registered him on the edges of my awareness, feeling annoyed but not angry that he was interfering.
                    </p>
                    <p class="typedText typedContent">
                        Amazingly, there was no violence in me, no desire to cause him harm. I was filled with a calm that descends when you act on that deep fear and anxiety: that unless something changes, we have no future, there is nothing to live for. Finally listening to the urgent warnings, you feel at peace. You can breathe. You are doing something."
                    </p>
                </div>
                <div ref="teztext3" style="opacity: 0; position: absolute; top: 0; left: 0;">
                    <p class="typedText typedContent">
                        "He got in my way, but I didn't hate him for it. Retrospectively, I'm grateful he tried to be a hero because of the extra publicity it brought to our demand to end fossil fuel extraction. At the time I was pretty upset to be covered in paint though.
                    </p>
                    <p class="typedText typedContent">
                        I told him why I was there, about our government's criminal inaction on the climate crisis, and how it's my duty as a citizen to call them out. This government is unfit to govern; it's our right and obligation to revolt.
                    </p>
                    <p class="typedText typedContent">
                        Before no less than 27 'parliamentary and diplomatic protection police' turned up, (I swear to god) mostly on motorbikes to arrest me, I gave a short piece to camera.
                    </p>
                    <p class="typedText typedContent">
                        Eyes filled with tears by the end. I was, and still am, furious and devastated. How dare we continue with business as usual? Furious that unless we stop exploiting every damn thing in the name of greed and profit, we have no livable future. Devastated about what we have done and continue to do to our planet, our home, its people, and its animals. How dare we."
                    </p>
                </div>
            </div>
        </div>
        <div class="moreContent">
            <div class="moreContentHalf" style="display: flex; flex-direction: column; justify-content: center; gap: 20px;">
                <img
                ref="tez5"
                data-aos="fade-in"
                data-aos-duration="1000"
                data-aos-once="true"
                data-aos-offset="200"
                src="../../assets/images/tez/tez-5.jpg"
                style="opacity: 0; width: 90%;"
                alt="">
                <img
                ref="tez6"
                data-aos="fade-in"
                data-aos-duration="1000"
                data-aos-once="true"
                data-aos-offset="200"
                src="../../assets/images/tez/tez-6.jpg"
                style="opacity: 0; width: 90%;"
                alt="">
            </div>
            <div class="moreContentHalf">
                <img
                ref="tez7"
                data-aos="fade-in"
                data-aos-duration="1000"
                data-aos-once="true"
                data-aos-offset="200"
                src="../../assets/images/tez/tez-7.jpg"
                style="opacity: 0; width: 100%; height: 100%; object-fit: contain;"
                alt="">
            </div>
        </div>
        <ProgressBar v-if="isInView" :time="time" ref="progressBar" />
    </div>
</template>