<script>
import ProgressBar from '../elements/ProgressBar.vue'
import { inject, computed } from 'vue';

export default {
    components: {
        ProgressBar,
    },
    setup() {
        const state = inject('state');
        const time = computed(() => {
            return state.slideshowActive ? 47000 : 39000;
        })
        return { state, time };
    },
    data() {
        return {
            isInView: false,
            chomsky1: null,
            chomsky2: null,
            chomskytext1: null,
            chomskytext2: null,
            chomskytext3: null,
            chomskytext4: null,
            chomskytext5: null,
            chomskytext6: null,
            hasShown: true,
        }
    },
    methods: {
        initAnimation() {
            this.hasShown = false;
            this.$emit('disable-move-functions');
            
            setTimeout(() => {
                this.chomsky1.setAttribute("data-aos", "fade-out");
                this.chomsky1.style.opacity = 0;

                setTimeout(() => {
                    this.chomsky2.style.opacity = 1;
                    this.chomsky2.setAttribute("data-aos", "fade-in");

                    setTimeout(() => {
                        this.chomskytext1.style.opacity = 1;
                        this.chomskytext1.setAttribute("data-aos", "fade-in");

                        setTimeout(() => {
                            this.chomskytext2.style.opacity = 1;
                            this.chomskytext2.setAttribute("data-aos", "fade-in");

                            setTimeout(() => {
                                this.chomskytext3.style.opacity = 1;
                                this.chomskytext3.setAttribute("data-aos", "fade-in");

                                setTimeout(() => {
                                    this.chomskytext4.style.opacity = 1;
                                    this.chomskytext4.setAttribute("data-aos", "fade-in");

                                    setTimeout(() => {
                                        this.chomskytext5.style.opacity = 1;
                                        this.chomskytext5.setAttribute("data-aos", "fade-in");

                                        setTimeout(() => {
                                            this.chomskytext6.style.opacity = 1;
                                            this.chomskytext6.setAttribute("data-aos", "fade-in")
                                            this.hasShown = true

                                            if(this.state.slideshowActive) {
                                                setTimeout(() => {
                                                const event = new KeyboardEvent('keydown', {'key':'ArrowDown'});
                                                window.dispatchEvent(event);
                                                }, 8000); // 8 seconds delay
                                            }

                                        // time between chomsky text five and six
                                        }, 5000);

                                    // time between chomsky text four and five
                                    }, 7000);

                                // time between chomsky text three and four
                                }, 7000);

                            // time between chomsky text twp and three
                            }, 5000);

                        // time between chomsky text one and two
                        }, 5000);

                    // time between chomsky's call image and first text appearing
                    }, 3000)

                // time between xr image and chomksy image
                }, 1000);

            // time that the xr arrest picture is in view.
            }, 5000);
        },

        resetAnimation() {
            const elements = [
                this.chomsky2,
                this.chomskytext1,
                this.chomskytext2,
                this.chomskytext3,
                this.chomskytext4,
                this.chomskytext5,
                this.chomskytext6,
            ];
            elements.forEach((element) => {
                element.style.opacity = 0;
            });
            this.chomsky1.style.opacity = 1;
            this.$refs.progressBar.reset();
            this.initAnimation();
        }
    },
    mounted() {
        this.$aos.init();

        this.chomsky1 = this.$refs.chomsky1;
        this.chomsky2 = this.$refs.chomsky2;
        this.chomskytext1 = this.$refs.chomskytext1;
        this.chomskytext2 = this.$refs.chomskytext2;
        this.chomskytext3 = this.$refs.chomskytext3;
        this.chomskytext4 = this.$refs.chomskytext4;
        this.chomskytext5 = this.$refs.chomskytext5;
        this.chomskytext6 = this.$refs.chomskytext6;

        const resetAnimationOnKeyDown = (event) => {
            if (event.key === 'ArrowLeft' && this.hasShown == true) {
                this.resetAnimation();
            }
        }

        const options = {
            rootMargin: '0px',
            threshold: 0.5,
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    this.isInView = true;
                    if (this.hasShown == null) {
                        this.initAnimation();
                    }
                window.addEventListener("keydown", resetAnimationOnKeyDown);
                } else {
                    window.removeEventListener("keydown", resetAnimationOnKeyDown);
                }
            })
        }, options);
        observer.observe(this.$refs.container);
    },
    watch: {
            hasShown(value) {
            if (value === false) {
                this.$emit('disable-move-functions');
            } else {
                this.$emit('enable-move-functions');
            }
        },
    },
}

</script>

<template>
    <div ref="container" class="container">
        <img
        ref="chomsky1"
        class="fullScreenImage"
        data-aos-duration="1000"
        data-aos-once="true"
        src="../../assets/images/chomsky/chomsky-1.jpg"
        alt="">
        <div class="moreContent">
            <img
            ref="chomsky2"
            class="fullScreenImage"
            data-aos="fade-in"
            data-aos-duration="1000"
            data-aos-once="true"
            style="opacity: 0"
            src="../../assets/images/chomsky/chomsky-2.jpg"
            alt="">
            <div class="moreContent">
                <div class="moreContentHalfText textGapContainer">
                    <div>
                        <img
                        ref="chomskytext1"
                        data-aos="fade-in"
                        data-aos-duration="1000"
                        data-aos-once="true"
                        src="../../assets/images/chomsky/text/chomsky-text-1.png"
                        alt=""
                        style="opacity: 0;">
                    </div>
                    <div>
                        <img
                        ref="chomskytext2"
                        data-aos="fade-in"
                        data-aos-duration="1000"
                        data-aos-once="true"
                        src="../../assets/images/chomsky/text/chomsky-text-2.png"
                        alt=""
                        style="opacity: 0; padding-bottom: 35px;">
                        <img
                        ref="chomskytext3"
                        data-aos="fade-in"
                        data-aos-duration="1000"
                        data-aos-once="true"
                        src="../../assets/images/chomsky/text/chomsky-text-3.png"
                        alt=""
                        style="opacity: 0; padding-left: 5px">
                    </div>
                </div>
                <div class="moreContentHalfText textGapContainer">
                    <div>
                        <img
                        ref="chomskytext4"
                        data-aos="fade-in"
                        data-aos-duration="1000"
                        data-aos-once="true"
                        src="../../assets/images/chomsky/text/chomsky-text-4.png"
                        alt=""
                        style="opacity: 0;">
                        <img
                        ref="chomskytext5"
                        data-aos="fade-in"
                        data-aos-duration="1000"
                        data-aos-once="true"
                        src="../../assets/images/chomsky/text/chomsky-text-5.png"
                        alt=""
                        style="opacity: 0; padding-top: 20px">
                    </div>
                    <div>
                        <img
                        ref="chomskytext6"
                        data-aos="fade-in"
                        data-aos-duration="1000"
                        data-aos-once="true"
                        src="../../assets/images/chomsky/text/chomsky-text-6.png"
                        alt=""
                        style="opacity: 0;">
                    </div>
                </div>
            </div>
        </div>
        <ProgressBar v-if="isInView" :time="time" ref="progressBar" />
    </div>
</template>
