<template>
    <div class="main-container">
        <h1>INTO THE GEARS OF THE MACHINE.</h1>
        <p class="main-subtitle" style="margin-bottom: 3px;">PRESS F11 FOR FULLSCREEN, ALLOW BROWSER MEDIA FOR MUSIC</p>
        <p class="main-subtitle main-subtitle-2" style="margin-bottom: 3px;">PRESS THE DOWN ARROW KEY TO START</p>
        <div class="controls">
            <div>
                <img src="/intro/left.svg" alt="">
                <p class="controls-text">REPLAY</p>
            </div>
            <div>
                <img src="/intro/up.svg" alt="">
                <p class="controls-text">PREV</p>
            </div>
            <div>
                <img src="/intro/down.svg" alt="">
                <p class="controls-text">NEXT</p>
            </div>
        </div>

        <a class="buy" href="../assets/ITGOTM.pdf" download>OR GET THE FULL PDF</a>
        <!-- <a @click="toggleSlideshow" href="/slideshow" class="buy">SLIDESHOW</a> -->

    </div>
</template>

<script>
import { inject } from 'vue';

export default {
  setup() {
    const state = inject('state');

    function toggleSlideshow() {
      state.slideshowActive = !state.slideshowActive;
    }

    return {
      toggleSlideshow,
    };
  },
}
</script>

<style scoped>
.main-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.controls {
    display: flex;
    gap: 20px;
    text-align: center;
    margin-top: 40px;
}

.controls img {
    height: 50px;
    width: 50px;
}

h1 {
    font-size: 3.5em;
    margin: 0;
    font-family: 'Elza', sans-serif;
    font-weight: 900;
    text-align: center;
}

.main-subtitle {
    margin: 10px 0;
    font-weight: bold;
    font-size: 1.5em;
    font-family: 'Elza', sans-serif;
    font-weight: 500;
    font-style: italic;
    text-align: center;
}

.main-subtitle-2 {
    color: #ee6b01;
    font-weight: 700;
}

.controls-text {
    font-family: 'Elza', sans-serif;
    text-align: center;
    margin: 0;
    font-weight: 700;
}

.buy {
    padding: 10px 20px;
    background: black;
    color: white;
    font-family: 'Elza', sans-serif;
    font-weight: 700;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
    margin-top: 20px;
    border-radius: 5px;
    transition: all 200ms;
}

.buy:hover {
    background: #ee6b01;
}

</style>